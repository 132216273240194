import React, { useEffect } from "react";
import MainPageFeature from "../features/main-page/MainPageFeature";
import "./MainPage.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

const MainPage = () => {
  useEffect(() => {
    const _title = "Index | Tabouleh";
    document.title = _title;
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: _title,
    });
  }, []);
  return (
    <div className="main-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tabouleh</title>
        <link rel="canonical" href="http://tab-bouleh.com/" />
        <meta
          name="description"
          content="Donate money to help the victims of Palestine. Simply watch the ads and help those in need."
        />
      </Helmet>
      <MainPageFeature />
    </div>
  );
};

export default MainPage;
