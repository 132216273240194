import "./App.css";
import AppRouter from "./routes/AppRouter";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import ReactGA from 'react-ga4'
import "react-toastify/dist/ReactToastify.css";

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID
ReactGA.initialize(GA_MEASUREMENT_ID)
const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <AppRouter />
      </QueryClientProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default App;
