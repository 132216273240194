import React, { useEffect, useState } from "react";
import "./AdminHome.css";
import AdminSignin from "../signin/AdminSignin";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase/config";
import UploadRevenue from "../upload-revenue/UploadRevenue";
import { toast } from "react-toastify";

const AdminHome = () => {
  const [user, setUser] = useState(undefined);

  const onPressLogin = async (username, password) => {
    toast.promise(loginAdmin(username, password), {
      pending: "Please wait while we are logging in",
      success: "Login successful 👌",
      error: "Login Failure 🤯",
    });
  };

  const loginAdmin = (username, password) => {
    try {
      return signInWithEmailAndPassword(auth, username, password)
        .then((userCredentials) => {
          console.log("Admin Login: success", userCredentials);
          setUser(userCredentials.user);
          return Promise.resolve();
        })
        .catch((error) => {
          console.log(
            "Admin Login: error while logging in Admin:",
            error.message
          );
          return Promise.reject();
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    return () => {
      setUser(undefined);
    };
  }, []);

  return (
    <div className="admin-home-root">
      {!user ? <AdminSignin onPressLogin={onPressLogin} /> : <UploadRevenue />}
    </div>
  );
};

export default AdminHome;
