import React, { useState } from "react";
import "./MainPageFeature.css";
import ReactSelect from "react-select";
import { cityImages, cityOptions } from "./meta";
import logo from "../../assets/images/logo.svg";
import Adsense from "../../components/ads/Adsense";
import Search from "../../components/search/Search";
import DateTimeComponent from "../../components/dateAndTime/DateTimeComponent";
import Logout from "../../components/logout/Logout";
import News from "../../components/news/News";
import GeneratedRevenue from "../../components/generated-revenue/GeneratedRevenue";
import ReactGA from 'react-ga4'

const customStyles = {
  control: (provided) => ({
    ...provided,
    background: "rgba(0, 0, 0, 0.40)",
    width: "200px",
    borderRadius: "40px",
    height: "50px",
    border: "none",
    color: "white",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "white",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided) => ({
    ...provided,
    background: "rgba(0, 0, 0, 0.40)",
    color: "white",
  }),
};

const MainPageFeature = () => {
  const [carouselImages, setCarouselImages] = useState(cityImages.Jerusalem);

  const handleCarouselChange = (e) => {
    setCarouselImages(cityImages[e.value]);

    ReactGA.event({
      category: "carousel",
      action: "change",
      label: e.value,
    });
  };

  return (
    <>
      <div
        className="main-page-feature-wrapper"
        style={{
          backgroundImage: 'url("/main-background.png")',
        }}>
        <Search />
        <DateTimeComponent />
        <div className="options-div">
          <GeneratedRevenue />
          <News />
        </div>

        <div className="top-heading-container">
          <img src={logo} alt="logo" className="logo-image" />
        </div>
        <div className="city-dropdown">
          <ReactSelect
            styles={customStyles}
            menuPlacement="top"
            defaultValue={cityOptions[cityOptions.length - 1]}
            options={cityOptions}
            onChange={handleCarouselChange}
          />
        </div>
      </div>

      {carouselImages && (
        <div
          className="main-page-feature-wrapper"
          style={{
            backgroundImage: carouselImages,
            zIndex: "70",
            position: "absolute",
            top: "0",
          }}></div>
      )}
    </>
  );
};

export default MainPageFeature;
