import React, { useEffect, useState } from "react";
import "./UploadRevenue.css";
import { getRevenueFromFirebase, updateRevenueInFrebease } from "./controlller";
import { toast } from "react-toastify";

const UploadRevenue = () => {
  const [currentRevenue, setCurrentRevenue] = useState(undefined);
  const [newRevenue, setNewRevenue] = useState(undefined);

  const uploadRevenueToFirebase = async () => {
    toast
      .promise(updateRevenueInFrebease(newRevenue), {
        pending: "Please Wait",
        success: "Revenue updates successfully 👌",
        error: "There was a problem while updating the revenue 🤯",
      })
      .then(() => {
        getRevenueFromFirebase(setCurrentRevenue);
      });
  };

  useEffect(() => {
    getRevenueFromFirebase(setCurrentRevenue);

    const inputs = document.querySelectorAll(".inp input");
    const handleInput = (event) => {
        const label = event.target.nextElementSibling;
        label.classList.toggle("up", event.target.value.trim() !== "");
      };
  
      inputs.forEach((input) => {
        input.addEventListener("input", handleInput);
  
        return () => {
          input.removeEventListener("input", handleInput);
        };
      });
  }, []);

  return (
    <div className="upload-revenue-root">
      <div className="upload-revenue-container">
        <div className="current-revenue">
          <h2>Current revenue</h2>
          <p> {currentRevenue} </p>
        </div>
        <div className="new-revenue">
          <h2>Enter new Revenue here</h2>
          <div className="inp">
            <input
              value={newRevenue}
              type="number"
              id="revenue"
              onChange={(event) => {
                setNewRevenue(event.target.value);
              }}
            />
            <label htmlFor="revenue">Revenue</label>
          </div>
          <button
            onClick={() => {
              uploadRevenueToFirebase();
            }}
            type="submit">
            Upload Revenue
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadRevenue;
