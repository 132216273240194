import React, { useState } from "react";
import "./Search.css";
import ReactSelect from "react-select";
import searchIcon from "../../assets/svg/search.svg";
import googleIcon from "../../assets/svg/google.svg";
import bingIcon from "../../assets/svg/bing.svg";
import duckDuckGoIcon from "../../assets/svg/duckduckgo.svg";
import ReactGA from 'react-ga4'


const Search = () => {
  const [searchEngine, setSearchEngine] = useState("google");
  const [searchQuery, setSearchQuery] = useState("");
  const searchEnginesObj = {
    google: `https://www.google.com/search?q=${searchQuery}`,
    duckduckgo: `https://duckduckgo.com/?q=${searchQuery}`,
    bing: `https://www.bing.com/search?q=${searchQuery}`
  }

  const handleSearch = (event) => {
    event.preventDefault();

    // Construct the search URL based on the selected search engine and query
    const searchUrl = searchEnginesObj[searchEngine] || '';

    // Open the search URL in a new tab
    window.open(searchUrl, "_blank");

    // register this event in GA
    ReactGA.event({
      category: "search",
      action: "click",
      label: searchEngine,
    });
  };

  const options = [
    {
      value: "google",
      label: (
        <div className="search-engine-dropdown-option">
          <img src={googleIcon} alt="google" /> Google
        </div>
      ),
    },
    {
      value: "duckduckgo",
      label: (
        <div className="search-engine-dropdown-option">
          <img src={duckDuckGoIcon} alt="google" /> DuckDuckGo
        </div>
      ),
    },
    {
      value: "bing",
      label: (
        <div className="search-engine-dropdown-option">
          <img src={bingIcon} alt="google" /> Bing
        </div>
      ),
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "rgba(0, 0, 0, 0.40)",
      width: "250px",
      borderRadius: "40px",
      height: "50px",
      border: "none",
      color: "white",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      background: "rgba(0, 0, 0, 0.40)",
      color: "white",
    }),
  };

  return (
    <div className="search-root search-position">
      <img className="search-icon" src={searchIcon} alt="search" />
      <form className="search-form" onSubmit={handleSearch}>
        <input
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          placeholder="Search"
          className="search-input"
        />
      </form>
      <ReactSelect
        defaultValue={options[0]}
        styles={customStyles}
        options={options}
        onChange={(selectedOption) => setSearchEngine(selectedOption.value)}
        menuPlacement="top"
      />
    </div>
  );
};

export default Search;
