import React, {  } from "react";
import { Route, Routes } from "react-router-dom";
import MainPage from "../pages/MainPage";
import "./AppRouter.css";
import AdminHome from "../pages/admin/home/AdminHome";

const AppRouter = () => {
  // const [userData, setUserData] = useState(undefined);
  // const [isLoginLoaded, setIsLoginLoaded] = useState(false);
  // const navigate = useNavigate();

  return (
    <>
      <div className="router-wrapper">
        <Routes>
          <Route path="/admin" element={<AdminHome />} exact={true} />
          <Route path="/" element={<MainPage />} exact={true} />
        </Routes>
      </div>
    </>
  );
};

export default AppRouter;
