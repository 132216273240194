import React, { useState, useEffect } from "react";
import './DateTimeComponent.css'

const DateTimeComponent = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const optionsTime = {
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    // hour12: true,
  };

  const optionsDate = {
    weekday: "long",
    // year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedTime = currentTime.toLocaleTimeString([], optionsTime);
  const formattedDate = currentTime.toLocaleDateString(undefined, optionsDate);

  return (
    <div className="date-time-root" >
      <div className="time" >{formattedTime}</div>
      <div className="date" >{formattedDate}</div>
    </div>
  );
};

export default DateTimeComponent;
