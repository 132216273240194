import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestoreDb } from "../../../firebase/config";

export const getRevenueFromFirebase = async (setCurrentRevenue) => {
  try {
    const docRef = doc(firestoreDb, "revenue", "FZQpMABHQ8oMWfLofZ3H");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("revenue data", docSnap.data());
      setCurrentRevenue(docSnap.data().moneyRaised);
    } else {
      setCurrentRevenue(undefined);
    }
  } catch (error) {
    console.log("error in getting revenue", error.mesage);
  }
};

export const updateRevenueInFrebease = async (revenue) => {
  const docRef = doc(firestoreDb, "revenue", "FZQpMABHQ8oMWfLofZ3H");
  await setDoc(docRef, { moneyRaised: revenue });
};
