import React, { useEffect, useState } from "react";
import "./AdminSignin.css";
import mainLogo from '../../../assets/images/logo.svg'

const AdminSignin = ({onPressLogin}) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const inputs = document.querySelectorAll(".inp input");

    const handleInput = (event) => {
      const label = event.target.nextElementSibling;
      label.classList.toggle("up", event.target.value.trim() !== "");
    };

    inputs.forEach((input) => {
      input.addEventListener("input", handleInput);

      return () => {
        input.removeEventListener("input", handleInput);
      };
    });
  }, []);

  return (
    <div className="admin-signin-root">
      <div className="container">
        <div className="pic2" />
        <img
          src={mainLogo}
          alt="logo"
        />
        <h1>Log in To Continue</h1>
        <div className="inp">
          <input
            value={userName}
            type="text"
            id="username"
            onChange={(event) => {
              setUserName(event.target.value);
            }}
          />
          <label htmlFor="username">Username</label>
        </div>
        <div className="inp">
          <input
            value={password}
            type="password"
            id="password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <label htmlFor="password">Password</label>
        </div>
        <button onClick={()=>onPressLogin(userName,password)} type="submit">Login</button>
      </div>
      <div className="pic"></div>
    </div>
  );
};

export default AdminSignin;
