import React, { useEffect, useState } from 'react'
import './GeneratedRevenue.css'
import { getRevenueFromFirebase } from '../../pages/admin/upload-revenue/controlller'

const GeneratedRevenue = () => {
    const [generatedRevenue, setGeneratedRevenue] = useState(0)

    useEffect(()=>{
        getRevenueFromFirebase(setGeneratedRevenue)
    },[])
  return (
    <div className='generated-revenue-root' >
        ${generatedRevenue}
    </div>
  )
}

export default GeneratedRevenue