import React, { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import "./News.css";
import { useDetectOutsideClick } from "../hooks/useDetectOutsideClick";
import ReactGA from "react-ga4";

const News = () => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  useEffect(() => {
    console.log("is active", isActive);
  }, [isActive]);

  const onMenuClick = () => {
    setIsActive(!isActive);
    ReactGA.event({
      category: "news",
      action: "click",
      label: "open news menu",
    });
  };

  const { isLoading, error, data } = useQuery(
    "getNews",
    () =>
      fetch(
        "https://newsdata.io/api/1/news?apikey=pub_356739947b74497b5e827f8331e1b04b5471d&qInTitle=gaza&language=en&domainurl=aljazeera.com",
        {
          method: "GET",
        },
      ).then((res) => res.json()),
    {
      // Set the cache time to 1 hour (in milliseconds)
      staleTime: 60 * 60 * 1000, // 1 hour
    },
  );

  return (
    <div className="dropdown-container">
      <div className="menu-container">
        <button onClick={onMenuClick} className="menu-trigger">
          <span>News</span>
        </button>
        <div
          className={`news-container  menu ${isActive ? "active" : "inactive"}`}
          ref={dropdownRef}>
          {isLoading === false &&
            !error &&
            data.results.map((news, index) => {
              return (
                <div className="card" key={index}>
                  <img src={news.image_url} alt={news.title} />
                  <div className="card-body">
                    <h2 className="card-title">{news.title}</h2>
                    <p className="card-text">{news.content}</p>
                    <a href={news.link} target="_blank" className="btn">
                      Explore
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default News;
