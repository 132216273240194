const cityNames = [
  "Jenin",
  "Tulkarm",
  "Jaffa",
  "Nablus",
  "Safad",
  "Haifa",
  "Gaza",
  "Jerusalem",
];

export const cityImages = {
  Jenin: ['url("/jenin-min.png")'],
  Tulkarm: ['url("/tulkram-min.png")'],
  Jaffa: ['url("/jaffa-min.png")'],
  Nablus: ['url("/nablus-min.png")'],
  Safad: ['url("/safad-min.png")'],
  Haifa: ['url("/haifa-min.png")'],
  Gaza: ['url("/gaza-min.png")'],
  Jerusalem: ['url("/juruslem.png")'],
};

export const cityOptions = cityNames.map((city, index) => {
  return { label: city, value: city };
});
